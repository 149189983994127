import useIsMinBreakpoint from '@hero/react-hooks/mediaQuery/useIsMinBreakpoint';
import Input from '@hero/ui-kit/inputs/Input';
import Container from '@hero/ui-kit/layout/Container';
import Section from '@hero/ui-kit/layout/Section';
import React from 'react';
import P from '@hero/ui-kit/typography/P';
import { useFormContext, useWatch } from '@hero/ui-kit/inputs/Form';
import { LeadDetailsResponse, LeadInsuranceResponse } from '../../../../../types';
import StandaloneCheckbox from '@hero/ui-kit/inputs/StandaloneCheckbox';
import useGetLeadInsurance from '../../../../../api/useGetLeadInsurance';
import ExistingHeroUserFormFields from '../../../../../components/ExistingHeroUserFormFields';
import DOB from '../../../../../components/LeadsForm/fields/DOB';
import VerifiedUser from '../../../../../components/VerifiedUser';

interface PatientDetailsFormProps {
    isLoading?: boolean;
    errorType?: string;
    lead?: LeadDetailsResponse;
    canEdit?: boolean;
}

const FormElements: React.FC<{
    isSaveDisabled?: boolean;
    showAdminEmailError?: boolean;
    showHeroSerialErrorError?: boolean;
    leadInsurance?: LeadInsuranceResponse;
    lead?: LeadDetailsResponse;
    canEdit?: boolean;
}> = ({ showAdminEmailError = false, showHeroSerialErrorError = false, lead, canEdit }) => {
    const isMinL = useIsMinBreakpoint('l');
    const isMember = useWatch({ name: 'is_member' });
    const {
        setValue,
        formState: { isDirty }
    } = useFormContext();

    React.useEffect(() => {
        setValue('isDirty', isDirty);
    }, [setValue, isDirty]);

    const isElectionMember = ['backoffice', 'mobile'].includes(lead?.form_version || '');

    const disabled = !canEdit;

    return (
        <>
            <Section noDefaultPadding>
                <div style={{ width: '100%', textAlign: 'left', marginBottom: 'var(--gutter)' }}>
                    <P
                        noDefaultMargin
                        strong
                        styles={{ fontSize: '2.4rem', marginBottom: '3.6rem' }}
                    >
                        Program applicant
                    </P>
                </div>
                <Container gridTemplateColumns={isMinL ? '1fr 1fr' : '1fr'} gridColumnGap="regular">
                    <Input disabled={disabled} name="first_name" displayName="First Name" />
                    <Input disabled={disabled} name="last_name" displayName="Last Name" />
                </Container>
                <Container gridTemplateColumns={isMinL ? '1fr 1fr' : '1fr'} gridColumnGap="regular">
                    <DOB displayName="Date of birth (MM-DD-YYYY)" disabled={disabled} />
                    <Input disabled={disabled} name="zip_code" displayName="zip" />
                </Container>
                <Container gridTemplateColumns={isMinL ? '1fr 1fr' : '1fr'} gridColumnGap="regular">
                    <Input disabled={disabled} name="patient_email" displayName="Patient Email" />
                    <Input
                        disabled={disabled}
                        name="patient_phone"
                        displayName="Patient Phone number"
                    />
                </Container>
                <Input
                    disabled={disabled}
                    name="external_user_id"
                    displayName="Patient ID (optional)"
                />
                {lead?.primary_user_details.external_serial &&
                lead.primary_user_details.admin_email &&
                lead.is_existing_member &&
                isElectionMember ? (
                    <VerifiedUser
                        adminEmail={lead.primary_user_details.admin_email}
                        serialNumber={lead.primary_user_details.external_serial}
                    />
                ) : (
                    <>
                        <div style={{ marginBottom: 'var(--gutter)' }}>
                            <StandaloneCheckbox
                                name="is_member"
                                labelText="Already using Hero dispenser"
                                disabled={disabled}
                            />
                        </div>
                        {isMember ? (
                            <ExistingHeroUserFormFields
                                canEdit={canEdit}
                                showAdminEmailError={showAdminEmailError}
                                showHeroSerialErrorError={showHeroSerialErrorError}
                            />
                        ) : null}
                    </>
                )}
            </Section>
        </>
    );
};

const PatientDetailsForm: React.FC<PatientDetailsFormProps> = ({
    errorType,
    isLoading,
    lead,
    canEdit
}) => {
    const { data: leadInsurance } = useGetLeadInsurance(lead?.primary_user_details.lead_id || '');

    return (
        <>
            <FormElements
                leadInsurance={leadInsurance}
                isSaveDisabled={isLoading}
                showAdminEmailError={errorType === 'email_not_exist'}
                showHeroSerialErrorError={errorType === 'serial_not_exist'}
                lead={lead}
                canEdit={canEdit}
            />
        </>
    );
};

export default PatientDetailsForm;
