import Modal, { Props as ModalProps } from '@hero/ui-kit/components/Modal';
import Button from '@hero/ui-kit/inputs/Button';
import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';
import P from '@hero/ui-kit/typography/P';
import React from 'react';
import useMemberReadmit from '../../api/useMemberReadmit';
import { MemberReadmitResponse } from '../../types';

type ReadmitModalProps = {
    memberName: string;
    memberId: number;
    onSuccess?: (response: MemberReadmitResponse) => void;
} & Pick<ModalProps, 'externalControls'>;

const ReadmitModal: React.FC<ReadmitModalProps> = ({
    externalControls,
    memberName,
    memberId,
    onSuccess
}) => {
    const { mutateAsync: readmitMember, isPending, isError } = useMemberReadmit();

    const [, setExternalState] = externalControls ?? [];

    const handleReadmit = () => {
        memberId > 0 &&
            readmitMember({ payload: { member_id: memberId } })
                .then((response) => {
                    onSuccess && onSuccess(response);
                    setExternalState && setExternalState(false);
                })
                .catch(() => {});
    };

    return (
        <Modal
            externalControls={externalControls}
            isCancelable
            styles={{ minWidth: '57rem', padding: 0 }}
        >
            {isError ? (
                <Section centered>
                    <H role="h5">Member cannot be re-admitted</H>
                    <P>
                        {memberName} cannot be re-admitted. They’ve most likely returned their Hero
                        dispenser or enrolled in another program. If you believe this is not the
                        case, please contact Hero support.
                    </P>

                    <Button onClick={() => setExternalState && setExternalState(false)}>
                        Got it
                    </Button>
                </Section>
            ) : (
                <Section centered>
                    <H role="h5">Re-admit member</H>
                    <P>
                        You are about to re-admit {memberName} as your RTM patient. This change will
                        take effect immediately.
                    </P>

                    <Button disabled={isPending} onClick={handleReadmit}>
                        Re-admit
                    </Button>
                </Section>
            )}
        </Modal>
    );
};

export default ReadmitModal;
