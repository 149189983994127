import ClickableOpacity from '@hero/ui-kit/inputs/ClickableOpacity';
import React from 'react';
import DeviceStatus from '../DeviceInfo/DeviceStatus';
import getIsDeviceSetupNeeded from '../../utils/getIsDeviceSetupNeeded';
import { useOrganizationMemberDetailsContext } from '../context/OrganizationMemberDetailsContext';
import getDeviceStatusLabel from '../../OrganizationMemberList/utils/getDeviceStatusLabel';
import Span from '@hero/ui-kit/typography/Span';
import { formatDistanceToNowStrict } from 'date-fns';
import P from '@hero/ui-kit/typography/P';
import * as Style from './device_info.module.scss';
import SideBar from '../../../../components/SideBar';
import Section from '@hero/ui-kit/layout/Section';
import { getFormattedDate } from '../../../../utils/date';
import Container from '@hero/ui-kit/layout/Container';

interface DeviceInfoProps {}

const MemberDeviceInfoStatus: React.FC<{ className?: string }> = ({ className }) => {
    const { currentDevice } = useOrganizationMemberDetailsContext();
    const isDeviceOnline = !!currentDevice?.online_status?.is_online;
    const lastOnline = currentDevice?.online_status?.last_online || undefined;
    const deviceSerial = currentDevice?.device_serial || 'N/A';

    const deviceStatus = currentDevice?.device_status || null;

    const isDeviceSetupNeeded = React.useMemo(() => {
        return getIsDeviceSetupNeeded(deviceStatus);
    }, [deviceStatus]);

    const deviceSerialLabel = React.useMemo(() => {
        if (['ORDERED', 'RETURN_NEEDED'].includes(currentDevice?.device_status || '')) {
            return (
                <>
                    <p>
                        <strong>{getDeviceStatusLabel(currentDevice?.device_status)}</strong> /
                        Tracking: <strong>{`${currentDevice?.tracking_number || 'N/A'}`}</strong>
                    </p>
                </>
            );
        }

        if (isDeviceSetupNeeded) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>{deviceSerial}</div>
                    <div>
                        <Span className={Style.alert}>{'Setup Needed'}</Span>
                    </div>
                </div>
            );
        }

        if (isDeviceOnline) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>{deviceSerial}</div>
                    <div className={Style.online}>
                        <Span>Online</Span>
                    </div>
                </div>
            );
        }

        if (!isDeviceOnline) {
            const lastOnlineDate = lastOnline ? new Date(lastOnline) : 'N/A';
            const lastOnlineDistance =
                lastOnlineDate instanceof Date
                    ? formatDistanceToNowStrict(lastOnlineDate)
                    : lastOnlineDate;
            return (
                <>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div>{deviceSerial}</div>
                        <div className={Style.alert}>
                            <Span>{`Offline ${lastOnlineDistance}`}</Span>
                        </div>
                    </div>
                </>
            );
        }

        if (deviceStatus === 'DELIVERED') {
            return (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>{deviceSerial}</div>
                    <div className={Style.warning}>
                        <Span>{getDeviceStatusLabel(deviceStatus)}</Span>
                    </div>
                </div>
            );
        }

        if (['DELIVERED', 'ORDERED', 'ACTIVATED', 'RETURN_NEEDED'].includes(deviceStatus || '')) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>{deviceSerial}</div>
                    <div>
                        <Span>{getDeviceStatusLabel(deviceStatus)}</Span>
                    </div>
                </div>
            );
        }

        return deviceSerial;
    }, [isDeviceOnline, deviceSerial, lastOnline, isDeviceSetupNeeded, deviceStatus]);
    return (
        <div className={`${Style.wrapper} ${className}`}>
            <DeviceStatus
                isDeviceOnline={isDeviceOnline}
                isDeviceSetupNeeded={isDeviceSetupNeeded}
                deviceStatus={deviceStatus}
            />
            <div style={{ marginLeft: '2.4rem' }}>
                {deviceSerial === 'N/A' ? (
                    <div>{getDeviceStatusLabel(deviceStatus)}</div>
                ) : (
                    <>
                        <P noDefaultMargin strong>
                            {deviceSerialLabel}
                        </P>
                    </>
                )}
            </div>
        </div>
    );
};

const MemberDeviceInfo: React.FC<DeviceInfoProps> = () => {
    const { currentDevice, memberDetails } = useOrganizationMemberDetailsContext();
    const { device_log } = memberDetails;
    const [showDeviceModal, setShowDeviceModal] = React.useState(false);

    const handleClick = () => {
        setShowDeviceModal(true);
    };

    const preparedDeviceStatusLogs = React.useMemo(() => {
        if (currentDevice?.device_statuses) {
            return currentDevice.device_statuses
                .filter((deviceStatus) => deviceStatus.status_timestamp !== null)
                .sort((a, b) => {
                    return (
                        new Date(b.status_timestamp || Date()).getTime() -
                        new Date(a.status_timestamp || Date()).getTime()
                    );
                })
                .map((deviceStatus) => ({
                    date: getFormattedDate(
                        new Date(deviceStatus.status_timestamp || Date()),
                        'MMM d, yyyy'
                    ),
                    time: getFormattedDate(
                        new Date(deviceStatus.status_timestamp || Date()),
                        'h:mm aa'
                    ),
                    status: getDeviceStatusLabel(deviceStatus.device_status)
                }));
        }
        return [];
    }, [currentDevice]);

    const eventTypes = {
        OPEN_DOOR: 'Safety key used to access meds',
        DEVICE_OFFLINE: 'Device went offline',
        null: 'No Event Type'
    };

    return (
        <>
            <ClickableOpacity alt="device-info" onClick={handleClick}>
                <MemberDeviceInfoStatus className={Style.hover} />
            </ClickableOpacity>

            {showDeviceModal ? (
                <SideBar
                    className={Style.sidebarContent}
                    onOutsideClick={() => setShowDeviceModal(false)}
                    listenToKeydown
                >
                    <Section padding="none" className={Style.sectionWrapper}>
                        <P
                            noDefaultMargin
                            size="large"
                            strong
                            centered
                            styles={{
                                fontSize: '2.4rem',
                                lineHeight: '3.2rem',
                                marginTop: '0.2rem',
                                marginBottom: '3.6rem'
                            }}
                        >
                            Device Log
                        </P>
                        <MemberDeviceInfoStatus />
                        {device_log?.past_seven_days?.length ? (
                            <>
                                <P
                                    noDefaultMargin
                                    strong
                                    styles={{ paddingBottom: '2.4rem', marginTop: '3.6rem' }}
                                >
                                    The last 7 days
                                </P>
                                <div className={Style.deviceLog}>
                                    {device_log?.past_seven_days.map((log) => {
                                        const count =
                                            typeof log.event_count === 'number'
                                                ? log.event_count
                                                : 0;

                                        return (
                                            <div
                                                className={Style.deviceLogItem}
                                                key={`${log.event_type}-${log.event_count}`}
                                            >
                                                <P noDefaultMargin strong>
                                                    {count > 0 ? `${log.event_count}x` : 0}
                                                </P>
                                                <P
                                                    noDefaultMargin
                                                    styles={{ marginLeft: '1.2rem' }}
                                                >
                                                    {eventTypes[`${log.event_type}`]}
                                                </P>
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        ) : null}
                        {preparedDeviceStatusLogs.length ? (
                            <>
                                <P strong styles={{ marginTop: '3.6rem' }}>
                                    Device Status log
                                </P>
                                <Container
                                    gridTemplateColumns={'1fr 1fr 1fr'}
                                    styles={{ marginBottom: '1.6rem' }}
                                >
                                    <P className={Style.deviceStatusLogHeader} noDefaultMargin>
                                        {'Date'}
                                    </P>
                                    <P className={Style.deviceStatusLogHeader} noDefaultMargin>
                                        {'Time'}
                                    </P>
                                    <P className={Style.deviceStatusLogHeader} noDefaultMargin>
                                        {'Status'}
                                    </P>
                                </Container>
                                {preparedDeviceStatusLogs.map((log, index) => (
                                    <Container
                                        key={`${index}-${log.status}`}
                                        gridTemplateColumns={'1fr 1fr 1fr'}
                                    >
                                        <P noDefaultMargin>{log.date}</P>
                                        <P noDefaultMargin>{log.time}</P>
                                        <P noDefaultMargin strong>
                                            {log.status}
                                        </P>
                                    </Container>
                                ))}
                            </>
                        ) : null}
                    </Section>
                </SideBar>
            ) : null}
        </>
    );
};

export default MemberDeviceInfo;
