import useIsMinBreakpoint from '@hero/react-hooks/mediaQuery/useIsMinBreakpoint';
import Input from '@hero/ui-kit/inputs/Input';
import Container from '@hero/ui-kit/layout/Container';
import Section from '@hero/ui-kit/layout/Section';
import P from '@hero/ui-kit/typography/P';
import React from 'react';
import DOB from '../../../../../LeadsManagement/components/LeadsForm/fields/DOB';

const PersonalForm: React.FC<{ canEdit?: boolean }> = ({ canEdit = true }) => {
    const isMinL = useIsMinBreakpoint('l');

    const disabled = !canEdit;

    return (
        <Section noDefaultPadding styles={{ width: '100%' }}>
            <div
                style={{
                    width: '100%',
                    textAlign: 'left',
                    marginBottom: '3.6rem',
                    marginTop: '0.4rem'
                }}
            >
                <P noDefaultMargin strong size="large" styles={{ marginBottom: '1.2rem' }}>
                    Personal
                </P>
            </div>
            <Container gridTemplateColumns={isMinL ? '1fr 1fr' : '1fr'} gridColumnGap="regular">
                <Input disabled={disabled} name="first_name" displayName="First Name" />
                <Input disabled={disabled} name="last_name" displayName="Last Name" />
            </Container>

            <Container gridTemplateColumns={isMinL ? '1fr 1fr' : '1fr'} gridColumnGap="regular">
                <DOB disabled={disabled} displayName="Date of birth (MM-DD-YYYY)" />
                <Input
                    disabled={disabled}
                    name="external_user_id"
                    displayName="Patient ID (Optional)"
                />
            </Container>
        </Section>
    );
};

export default PersonalForm;
